import './Hold.css';

function Hold({ isVisible }) {
    return (
        <div className={`Hold ${isVisible ? '' : 'hidden'}`}>
            HOLD
        </div>
    );
}

export default Hold;