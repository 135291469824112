import Card from './Card.js';
import Hold from './Hold.js';

function Hand({ cards, selectedCards, onCardSelection }) {
    return (
        <div className="cards">
          {cards.map((card, index) => (
            <div key={index}>
              <Hold isVisible={selectedCards.includes(card)} />
              <Card key={index} card={card} onCardClick={onCardSelection} />
            </div>
          ))}
        </div>
      );
}

export default Hand;