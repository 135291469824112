const SUITS = ["♠", "♣", "♥", "♦"];
const VALUES = [
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "J",
    "Q",
    "K",
    "A"
];


export default class Deck {
    constructor(cards = freshDeck()) {
        this.cards = cards;
    }

    get numberOfCards() {
        return this.cards.length;
    }

    pop() {
        return this.cards.shift();
    }

    push(card) {
        this.cards.push(card);
    }

    shuffle() {
        for (let i = this.numberOfCards - 1; i > 0; i--) {
            const newIndex = Math.floor(Math.random() * (i + 1));
            const oldValue = this.cards[newIndex];
            this.cards[newIndex] = this.cards[i];
            this.cards[i] = oldValue;
        }
    }
}

class Card {

    constructor(suit, value) {
        this.suit = suit;
        this.value = value;
    }

    get color() {
        return this.suit === '♥' || this.suit === '♦' ? 'red' : 'black';
    }

}

function freshDeck() {
    return SUITS.flatMap(suit => {
        return VALUES.map(value => {
            return new Card(suit, value);
        });
    });
}

export function evaluateHand(cards) {
    // Sort the cards by value
    cards.sort((a, b) => a.value - b.value);

    // Count occurrences of each value and suit
    const valueCount = {};
    const suitCount = {};
    for (const card of cards) {
        valueCount[card.value] = (valueCount[card.value] || 0) + 1;
        suitCount[card.suit] = (suitCount[card.suit] || 0) + 1;
    }
    console.log(valueCount);
    console.log(suitCount);

    // Check for flush
    const flush = Object.values(suitCount).some(count => count === 5);

    // Check for straight
    let straight = false;
    if (valueCount.length === 5) {
        for (let i = 0; i < cards.length - 4; i++) {
            if (cards[i + 4].value - cards[i].value === 4) {
                straight = true;
                break;
            }
        }
    }

    // Check for straight flush and royal flush
    let straightFlush = false;
    let royalFlush = false;
    if (flush && straight) {
        straightFlush = true;
        if (cards[0].value === 10) {
            royalFlush = true;
        }
    }

    // Check for a pair of jacks or higher
    let pairJacksOrBetter = false;
    for (const value in valueCount) {
        if (valueCount[value] >= 2 && ['J', 'Q', 'K', 'A'].includes(value)) {
            pairJacksOrBetter = true;
            break;
        }
    }

    // Check for other hands
    let hand = '';
    if (royalFlush) {
        hand = 'Royal Flush';
    } else if (straightFlush) {
        hand = 'Straight Flush';
    } else if (Object.values(valueCount).some(count => count === 4)) {
        hand = '4 of a Kind';
    } else if (Object.values(valueCount).some(count => count === 3) &&
        Object.values(valueCount).some(count => count === 2)) {
        hand = 'Full House';
    } else if (flush) {
        hand = 'Flush';
    } else if (straight) {
        hand = 'Straight';
    } else if (Object.values(valueCount).some(count => count === 3)) {
        hand = '3 of a Kind';
    } else if (Object.values(valueCount).filter(count => count === 2).length === 2) {
        hand = 'Two Pair';
    } else if (pairJacksOrBetter) {
        hand = 'Jacks or Better';
    }

    return hand;
}