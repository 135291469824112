import './Card.css';
import cardBack from './red2.svg';

function Card({ card, onCardClick }) {
    if (!card) {
        return (
            <div className="Card card-back">
                <img src={cardBack} alt="Card back" draggable="false"></img>
            </div>
        );
    }

    let colorClassName = card.color;
    let dataValue = card.value + ' ' + card.suit;

    const handleCardClick = (card) => {
        onCardClick(card);
    };

    return (
        <div className={`Card ${card.color}`} onClick={() => handleCardClick(card)}>
            <div className='top-left'>
                <div className='card-value'>{card.value}</div>
                <div className='card-suit'>{card.suit}</div>
            </div>
            <div className='big-suit'>{card.suit}</div>
        </div>
    );
}

export default Card;