import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import Deck, { evaluateHand } from './deck.js';
import Hand from './Hand.js';

const paytable = {
  "Royal Flush": 250,
  "Straight Flush": 50,
  "4 of a Kind": 25,
  "Full House": 9,
  "Flush": 6,
  "Straight": 4,
  "3 of a Kind": 3,
  "Two Pair": 2,
  "Jacks or Better": 1
}

const getClassNameForPaytableRow = (handType, winningHand) => {
  return winningHand === handType ? 'winning-hand' : '';
}

function playCardFlip() {
  let audio = new Audio('./cardFlip.mp3');
  audio.play();
}

function playWin() {
  let audio = new Audio('./win.mp3');
  audio.play();
}

function sleep(ms) {
  //console.log('sleeping: ' + ms + 'ms');
  return new Promise(resolve => setTimeout(resolve, ms));
}

function App() {
  const [cards, setCards] = useState(Array.from({ length: 5 }, () => null));
  const [selectedCards, setSelectedCards] = useState([]);
  const [payTableRows, setPayTableRows] = useState(null);
  const [dealOrDraw, setDealOrDraw] = useState('deal');
  const [loading, setLoading] = useState(false);
  const [winningHand, setWinningHand] = useState(null);
  const deckRef = useRef(null);

  useEffect(() => {
    let newPayTable = Object.entries(paytable).map(([key, value]) => {
      const bet5 = key === "Royal Flush" ? value * 16 : value * 5;
      return (
        <tr key={key} className={getClassNameForPaytableRow(key, winningHand)}>
          <td className="payTableType">{key.toUpperCase()}</td>
          <td>{value}</td>
          <td>{value * 2}</td>
          <td>{value * 3}</td>
          <td>{value * 4}</td>
          <td>{bet5}</td>
        </tr>
      );
    });

    setPayTableRows(newPayTable);
  }, [winningHand]);

  const handleCardSelection = (card) => {
    if (!card || dealOrDraw !== 'draw') return;

    setSelectedCards(prevSelectedCards => {
      const index = prevSelectedCards.indexOf(card);
      if (index !== -1) {
        const updatedSelectedCards = [...prevSelectedCards];
        updatedSelectedCards.splice(index, 1);
        return updatedSelectedCards;
      }
      return [...prevSelectedCards, card];
    });
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the pressed key is a number between 1 and 5
      if (event.key >= '1' && event.key <= '5') {
        const index = parseInt(event.key) - 1;
        if (index >= 0 && index < cards.length) {
          const card = cards[index];
          handleCardSelection(card);
        }
      }

      // Check if the pressed key if the space bar
      else if (event.key === ' ') {
        event.preventDefault();
        if (dealOrDraw === 'deal') {
          handleDeal();
        }
        else if (dealOrDraw === 'draw') {
          handleDraw();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [cards, handleCardSelection]);

  // Deal will create a new deck and give the player five new cards
  // This is the start of the round and the first action of any game
  const handleDeal = async () => {
    if (dealOrDraw !== 'deal') return;
    setWinningHand(null);

    setLoading(true);
    setCards(Array.from({ length: 5 }, () => null));
    const deck = new Deck();
    deck.shuffle();
    deckRef.current = deck;

    for (let i = 0; i < cards.length; i++) {
      const newCard = deck.pop();
      setCards(prevCards => {
        const newCards = [...prevCards];
        newCards[i] = newCard;
        return newCards;
      });
      playCardFlip();
      await sleep(250); // Delay between dealing each card
    }

    setSelectedCards([]);
    setDealOrDraw('draw');
    setLoading(false);
  }

  // Draw will draw new cards for every card the player is not currently holding
  // during the Draw phase of the game is only when a player can hold a card.
  const handleDraw = async () => {
    if (dealOrDraw !== 'draw') return;
    setLoading(true);

    setCards(cards.map((card) => {
      if (selectedCards.includes(card)) {
        return card;
      }
      return null;
    }));

    let deck = deckRef.current;
    let newHand = [];
    for (let i = 0; i < cards.length; i++) {
      if (!selectedCards.includes(cards[i])) {
        const newCard = deck.pop();
        setCards(prevCards => {
          const newCards = [...prevCards];
          newCards[i] = newCard;
          newHand = structuredClone(newCards);
          return newCards;
        });
        playCardFlip();
        await sleep(250); // Delay between dealing each card
      }
    }

    let hand = evaluateHand(newHand);
    console.log('HAND: ' + hand);
    if (hand) {
      playWin();
    }

    setWinningHand(hand);
    setDealOrDraw('deal');
    setLoading(false);
  }



  return (
    <div className="App">
      <div className="paytable">
        <table>
          <tbody>
            {payTableRows}
          </tbody>
        </table>
      </div>

      <div className="cards">
        <Hand cards={cards} selectedCards={selectedCards} onCardSelection={handleCardSelection} />
      </div>
      <div className={dealOrDraw}>
        {dealOrDraw === 'deal' ? (
          <button type='button' className='deal-button' onClick={handleDeal} disabled={loading}>DEAL</button>
        ) : (
          <button type='button' className='draw-button' onClick={handleDraw} disabled={loading}>DRAW</button>
        )}
      </div>
    </div>
  );
}

export default App;
